<template>
  <article class="post-location">
    <RichText class="post-location__content" :value="$path('customFields.content', value)" />
    <section class="container">
      <Masonry :value="$path('customFields.images', value)" />
    </section>
    <AreaInfo :value="[$path('customFields.area', value)]" />
    <section v-if="$path('customFields.viewer.url', value)" class="container">
      <h2>{{ $path('customFields.viewer.title', value) }}</h2>
      <iframe
        class="post-location__viewer"
        :src="$path('customFields.viewer.url', value)"
        frameborder="0"
      ></iframe>
    </section>
    <Features
      v-if="FeaturesNotEmpty($path('customFields.features', value))"
      :value="$path('customFields.features', value)"
      background="transparent"
      gap="3rem"
    />
    <RichText
      v-if="$path('customFields.projects-intro', value)"
      :value="$path('customFields.projects-intro', value)"
    />
    <ProjectCards
      v-if="ProjectCardsNotEmpty($path('customFields.projects', value))"
      :value="$path('customFields.projects', value)"
      background="transparent"
      gap="3rem"
    />
    <RichText
      v-if="$path('customFields.map-content', value)"
      :value="$path('customFields.map-content', value)"
    />
    <ImageComponent
      :value="$path('customFields.map', value)"
      class="post-location__map container"
    />
  </article>
</template>

<script>
import { FeaturesNotEmpty, ProjectCardsNotEmpty } from '@/utils'
import PostComponents from '@/components/Post'
import Gallery from '@/components/Gallery'
import RichText from '@/components/RichText'
import ImageComponent from '@kvass/media-render/Types/Image'

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    FeaturesNotEmpty,
    ProjectCardsNotEmpty,
  },
  components: {
    ...PostComponents,
    Gallery,
    RichText,
    ImageComponent,
  },
}
</script>

<style lang="scss">
.post-location {
  padding-bottom: 3rem;

  &__content {
    padding-top: 6rem;
    max-width: 1000px;

    @include respond-below('phone') {
      padding-top: 3rem;
    }
  }

  &__viewer {
    width: 100%;
    aspect-ratio: 16/9;

    @include respond-below('phone') {
      aspect-ratio: 10/16;
    }
  }

  &__map {
    @include respond-below('phone') {
      .masonry__item:before {
        padding-top: 150%;
      }
    }
  }
}
</style>
