<template>
  <article class="post-areals">
    <RichText :value="$path('customFields.content', value)" />
    <section class="post-areals__residentials container">
      <h2 class="container" style="max-width: 1000px; width: 100%">
        Arealtabell - totalt 6 150 kvm
      </h2>
      <Residentials />
    </section>
    <section v-if="$path('customFields.plans', value).length" class="post-areals__plans container">
      <h2 class="container" style="max-width: 1000px; width: 100%">Plantegninger</h2>
      <Masonry v-for="(item, index) in $path('customFields.plans', value)" :value="[item]" />
    </section>
  </article>
</template>

<script>
import PostComponents from '@/components/Post'
import RichText from '@/components/RichText'
import Residentials from '@/components/Residentials'

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    ...PostComponents,
    RichText,
    Residentials,
  },
}
</script>

<style lang="scss">
.post-areals {
  &__plans {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    padding-block: 6rem;
  }
}
</style>
