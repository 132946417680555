<template>
  <article class="post-facilities">
    <RichText class="post-facilities__content" :value="$path('customFields.content', value)" />
    <Features
      v-if="hasFeatures"
      :value="$path('customFields.facilities', value)"
      background="transparent"
      gap="3rem"
    />
    <Masonry
      v-for="(gallery, i) in images"
      :key="i"
      :value="gallery"
      theme="None"
      class="container"
    />
  </article>
</template>

<script>
import { FeaturesNotEmpty } from '@/utils'
import PostComponents from '@/components/Post'
import RichText from '@/components/RichText'

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    images() {
      return this.$path('value.customFields.images') || []
    },
    hasFeatures() {
      return FeaturesNotEmpty(this.$path('value.customFields.facilities'))
    },
  },
  components: {
    ...PostComponents,
    RichText,
  },
}
</script>

<style lang="scss">
.post-facilities {
  padding-bottom: 6rem;

  &__content {
    max-width: 1000px;

    @include respond-below('phone') {
      padding-top: 3rem;
    }
  }
}
</style>
