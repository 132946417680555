<template>
  <article class="post-about">
    <RichText :value="$path('customFields.content', value)" />
    <Features
      v-if="FeaturesNotEmpty($path('customFields.features', value))"
      :value="$path('customFields.features', value)"
      background="transparent"
      gap="2rem"
    />
    <Masonry
      v-for="(item, index) in $path('customFields.images', value)"
      :key="index"
      :value="item"
      theme="None"
      class="container"
    />
    <RichText :value="$path('customFields.more-content', value)" />
    <Features
      v-if="FeaturesNotEmpty($path('customFields.more-features', value))"
      :value="$path('customFields.more-features', value)"
      background="transparent"
      gap="3rem"
    />
    <AreaInfo :value="interior" background="var(--secondary)" pattern />
  </article>
</template>

<script>
import { FeaturesNotEmpty } from '@/utils'
import PostComponents from '@/components/Post'
import RichText from '@/components/RichText'

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    interior() {
      const images = this.$path('value.customFields.interior-images')
      const text = this.$path('value.customFields.interior-text')

      return [{ content: text, image: images }]
    },
  },
  methods: {
    FeaturesNotEmpty,
  },
  components: {
    ...PostComponents,
    RichText,
  },
}
</script>

<style lang="scss">
.post-about {
  &__content {
    padding-top: 6rem;
    max-width: 1000px;

    @include respond-below('phone') {
      padding-top: 3rem;
    }
  }
}
</style>
