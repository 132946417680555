<template>
  <Masonry v-if="value && value.length" :value="value" class="post-masonry" />
</template>

<script>
import Masonry from '@kvass/template-gallery/Types/Masonry'

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Masonry,
  },
}
</script>

<style lang="scss">
.post-masonry {
  .masonry {
    &__content {
      gap: 1rem;

      &.masonry__content--length-1 {
        grid-template-columns: 1fr;
      }

      &.masonry__content--length-3 {
        grid-template-rows: repeat(2, 1fr);

        .masonry__item:first-child {
          grid-row-end: span 2;
        }
      }

      @include respond-above('tablet') {
        &:not(.masonry__content--length-1) {
          grid-template-columns: repeat(2, 1fr);
        }
      }

      @include respond-below('phone') {
        gap: 0.5rem;
      }
    }
  }
}
</style>
