<template>
  <section class="post-projects" v-if="value.length">
    <div class="container">
      <div class="post-projects__items">
        <div
          class="post-projects__item"
          :style="`align-items: ${align}; background-color: ${background}`"
          v-for="project in value"
        >
          <ImageComponent
            v-if="getImage(project)"
            class="post-projects__item-image"
            :value="getImage(project)"
          />
          <div class="post-projects__item-content" v-html="project.content"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ImageComponent from '@kvass/media-render/Types/Image'

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    align: {
      type: String,
      default: 'start',
      validator(val) {
        return ['start', 'center', 'end'].includes(val)
      },
    },
    background: String,
    gap: {
      type: String,
      default: '1rem',
    },
  },
  methods: {
    getImage(item) {
      return item.icon || item.image || {}
    },
  },
  components: {
    ImageComponent,
  },
}
</script>

<style lang="scss">
.post-projects {
  padding-bottom: 6rem;

  @include respond-below('phone') {
    padding-block: 3rem;
  }

  &__items {
    display: grid;
    gap: v-bind(gap);
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  &__item {
    min-height: 200px;
    background-color: $grey;
    display: flex;
    flex-direction: column;
    justify-content: end;

    border-bottom: 0.4rem solid var(--primary);

    &-image img {
      aspect-ratio: 16/9;
      width: 100%;
    }

    &-content {
      margin: 0;
      padding: 0.5rem 1rem;
    }
  }
}
</style>
