<template>
  <section
    v-if="value"
    class="image-gallery container"
    :class="`gallery--length-${value.length}`"
    :style="`grid-template-columns: repeat(${columns}, 1fr)`"
  >
    <ImageComponent
      v-for="(item, index) in value"
      v-bind="$attrs"
      :key="index"
      :value="item"
      :style="`grid-row: span ${getLayout(index).rows}; grid-column: span ${getLayout(index).cols}`"
      :caption-placement="captionPlacement"
    />
  </section>
</template>

<script>
import ImageComponent from '@kvass/media-render/Types/Image.vue'

export default {
  props: {
    columns: {
      type: Number,
      default: 2,
    },
    layout: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
    captionPlacement: String,
  },
  methods: {
    getLayout(index) {
      return this.layout[index] || { rows: 1, cols: 1 }
    },
  },
  components: {
    ImageComponent,
  },
}
</script>

<style lang="scss">
.image-gallery {
  display: grid;
  gap: 1rem;
  padding-block: 0.5rem;

  @include respond-below('tablet') {
    grid-template-columns: 1fr;
  }

  .kvass-media-render-image {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
</style>
