<template>
  <section
    class="post-area-info"
    :class="{ 'post-area-info--pattern': pattern }"
    v-if="value.length"
  >
    <div class="post-area-info__items">
      <div class="post-area-info__item" v-for="(item, i) in value" :key="i">
        <div class="post-area-info__item-content container" v-html="item.content"></div>
        <Masonry
          v-for="(image, index) in toArray(item.image)"
          :key="`image_${index}`"
          class="container"
          :value="image"
        />
      </div>
    </div>
  </section>
</template>

<script>
import Masonry from './Masonry'

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    background: {
      type: String,
      default: 'transparent',
    },
    pattern: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toArray(item) {
      return item[0] instanceof Array ? item : [item]
    },
  },
  components: {
    Masonry,
  },
}
</script>

<style lang="scss">
.post-area-info {
  --spacing: 6rem;
  padding-block: var(--spacing);
  background-color: v-bind(background);

  @include respond-below('phone') {
    --spacing: 3rem;
    background-size: 60%;
  }

  &--pattern {
    background-image: url('https://assets.kvass.no/634fe0321d556172793cce11');
    background-size: 20%;
    background-repeat: repeat;
    background-blend-mode: color-dodge;
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: var(--spacing);
  }

  &__item {
    &-content {
      max-width: 1000px;
      margin-bottom: var(--spacing);
    }
  }
}
</style>
