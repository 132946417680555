<template>
  <section class="rich-text container" :style="`text-align: ${textAlign}`">
    <div class="rich-text__content" v-html="value"></div>
  </section>
</template>

<script>
export default {
  props: {
    value: String,
    textAlign: {
      type: String,
      default: 'start',
    },
  },
}
</script>

<style lang="scss">
.rich-text {
  padding-block: 6rem;
  max-width: 1000px;

  @include respond-below('tablet') {
    padding-block: 4rem;
  }
}
</style>
