d
<template>
  <article class="post-owner">
    <div class="post-owner__content container">
      <div v-html="$path('customFields.content.text', value)"></div>
      <ImageComponent :value="$path('customFields.content.logo', value)" />
    </div>
  </article>
</template>

<script>
import ImageComponent from '@kvass/media-render/Types/Image'

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    ImageComponent,
  },
}
</script>

<style lang="scss">
.post-owner {
  &__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-block: 12rem;

    @include respond-below('phone') {
      grid-template-columns: 1fr;
      padding-block: 6rem;
    }
  }
}
</style>
