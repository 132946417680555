<template>
  <MapComponent class="post-map" :value="$path('address', item)" />
</template>

<script>
import { mapState } from 'vuex'
import MapComponent from '@/components/Map'

export default {
  computed: {
    ...mapState('Post', ['item']),
  },
  components: {
    MapComponent,
  },
}
</script>
