<template>
  <Loader :value="promises.fetch" class="post" :key="post.id">
    <Hero v-if="cover.length" :description="post.content" :images="cover" :hide-stats="true" />
    <component v-if="component" :is="component" :value="post" />
    <CallToAction />
  </Loader>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Types from './Types'
import Hero from '@/components/Hero'
import CallToAction from '@/components/CallToAction'

export default {
  props: {
    slug: {
      type: String,
      default: 'post',
    },
  },
  computed: {
    ...mapState('Post', ['promises', 'item']),
    post() {
      if (!this.item.posts) return {}
      return this.item.posts.find(p => p.slug === this.slug)
    },
    cover() {
      return this.$path('post.media.cover') || []
    },
    source() {
      const sources = this.post.customFieldsConfig?.map(schema => schema.source) || []
      return sources[0]
    },
    component() {
      switch (this.source) {
        case 'about':
          return Types.About
        case 'location':
          return Types.Location
        case 'facilities':
          return Types.Facilities
        case 'owner':
          return Types.Owner
        case 'areals':
          return Types.Areals
        default:
          return Types.Article
      }
    },
  },
  methods: {
    ...mapActions('Post', ['fetch']),
  },
  created() {
    this.fetch()
  },
  metaInfo() {
    return {
      title: this.$path('title', this.post),
    }
  },

  components: {
    ...Types,
    Hero,
    CallToAction,
  },
}
</script>

<style lang="scss">
.post {
}
</style>
